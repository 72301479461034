<template>
  <div class="bg" :style="imgUrl">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>

import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";

export default {
  name: "fifteenPage",
  components: {ClassBut},
  data() {
    return {
      imgUrl: {}
    }
  },
  created() {
    this.gitApicourseTalk()
  },
  methods: {
    forward() {
      this.$router.push({
        name:'PageSixteenth',
        query: this.$route.query,
      })
    },
    async gitApicourseTalk() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseUpClassAdviser`, {
        classId: this.$route.query.classId,
      })
      this.imgUrl = {backgroundImage: `url("${res.imgUrl}")`}
    },
  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

</style>
